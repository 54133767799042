* {
  margin: 0;
  font-family: "Andika New Basic regular";
}

.App {
  text-align: center;
  /* background-color: whitesmoke; */
}

a {
  text-decoration: none;
  color: black;
}
