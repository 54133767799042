.imageupload {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.imageupload__progress {
  width: 100%;
}

.imageupload > form {
  display: flex;
  justify-content: left;
}

.form {
  display: flex;
  flex-direction: column;
}
