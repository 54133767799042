@import "./fonts/Andika.css";

// .header {
//   display: flex;

//   padding-top: 25px;
//   padding-bottom: 25px;
//   align-items: center;
//   // padding-left: 15%;
//   // padding-right: 15%;
//   position: sticky;
//   top: 0;
//   font-family: "Andika New Basic bold";

//   background: linear-gradient(
//     0deg,
//     rgba(138, 138, 138, 0.7679446778711485) 0%,
//     rgba(138, 138, 138, 0.13) 0%,
//     rgba(255, 255, 255, 1) 100%
//   );
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//   z-index: 100;
// }

.header__text {
  display: flex;
  align-items: center;
}

.header__about {
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.header__link {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

// .header__icon {
//   padding-right: 5px;
// }

// .header__loggedIn {
//   display: flex;
//   flex-direction: column;
//   display: 1 1 auto;
// }

// .header__loggedIn > p {
//   margin-right: 10px;
// }
///// burger::after

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  height: 50px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: black;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 700px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    z-index: 1;
    top: 0;
    margin-top: 82px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 3.5em;

    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;

    backdrop-filter: blur(5px);
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}
