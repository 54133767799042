.about {
  display: flex;
  text-justify: auto;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  padding-bottom: 40px;
  font-size: 18px;
  @media (max-width: 558px) {
    flex-direction: column;
  }
}

.about__lars {
  max-width: 30%;
  text-align: left;
  @media (max-width: 558px) {
    max-width: 100%;
    padding: 0 20px 0 20px;
  }
}

.about__portfolie {
  max-width: 30%;
  padding-left: 40px;
  text-align: left;
  @media (max-width: 558px) {
    max-width: 100%;
    padding-top: 50px;
    padding: 0 20px 0 20px;
  }
}

.about__image {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.about__image img {
  padding-top: 20px;
  max-height: 400px;
  max-width: 300px;
}
