/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Andika New Basic Regular';
font-style: normal;
font-weight: normal;
src: local('Andika New Basic Regular'), url('AndikaNewBasic-R.woff') format('woff');
}


@font-face {
font-family: 'Andika New Basic Italic';
font-style: normal;
font-weight: normal;
src: local('Andika New Basic Italic'), url('AndikaNewBasic-I.woff') format('woff');
}


@font-face {
font-family: 'Andika New Basic Bold';
font-style: normal;
font-weight: normal;
src: local('Andika New Basic Bold'), url('AndikaNewBasic-B.woff') format('woff');
}


@font-face {
font-family: 'Andika New Basic Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Andika New Basic Bold Italic'), url('AndikaNewBasic-BI.woff') format('woff');
}