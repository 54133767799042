.artCard {
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 0 auto;
  min-height: 400px;
}

.artCard__title {
  min-height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.artCard__image {
  object-fit: contain;
  border-top: 1px solid lightgray;
  max-width: 200px;

  min-height: 300px;
}

.artCard__image:hover {
  cursor: pointer;
}
