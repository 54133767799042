.art {
  /* margin-top: 50px;
  padding: 0 5% 0 5%;
  display: flex;

  justify-content: center; */
}

.art__imageContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 30px;
  margin: 50px 200px 0 200px;
}

.art__imageContainerMobile {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 30px;
}
